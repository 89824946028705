.App {
  text-align: center;

}

.Top {
  background-color: #FFC64E;
  height: 40vh;
  /* width: 100vw; */
  /* display: flex; */
  align-items: center;
  justify-content: center;
  color: black;
  font-family: -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen-Sans,
    Ubuntu,
    Cantarell,
    "Helvetica Neue",
    sans-serif;
  /* width: 400px;
  height: 300px; */
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.searchbar {
  -webkit-appearance: none;
  outline: none;
  box-shadow: none !important;
  border: none;
  background-color: transparent;
  /* background-color: red; */
  border-bottom: 1px solid black;
  width: 300px;
  font-size: large;
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 25px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
